<template>
  <div class="comment" v-if="message && !isAdmin">
    <el-alert :title="message" type="info" effect="dark" :closable="false"/>
  </div>

  <el-form ref="ruleFormRef" :model="registerForm" :rules="rules" :label-position="labelPosition"
           label-width="120px" :disabled="isDisableForm">
    <TabStrip :selected="selectedTab" @select="onSelectTab" :tabs="tabs">
      <template v-slot:TabOne>
        <div style="padding-bottom: 10px;">
          <el-form-item label="Инн" prop="inn">
            <el-input v-model="registerForm.inn" :disabled="editDisabled"/>
          </el-form-item>

          <el-button :disabled="editDisabled || (registerForm.inn.length === 0)" size="small"
                     v-loading="loadingRequisites" type="primary" @click="loadRequisites()">
            Подгрузить реквизиты
          </el-button>
        </div>

        <el-form-item label="Организационно-правовая форма и фирменное наименование (полное)"
                      prop="full_name">
          <el-input v-model="registerForm.full_name" :disabled="editDisabled"/>
        </el-form-item>

        <el-form-item label="Организационно-правовая форма и фирменное наименование (краткое)"
                      prop="short_name">
          <el-input v-model="registerForm.short_name" :disabled="editDisabled"/>
        </el-form-item>

        <el-form-item disabled="editDisabled"
                      label="Свидетельство о постановке на учет юридического лица в налоговом органе по месту нахождения на территории РФ (дата и номер, кем выдано)"
                      prop="registration_certificate">
          <el-input v-model="registerForm.registration_certificate" :disabled="editDisabled"/>
        </el-form-item>

        <el-form-item label="ОГРН" prop="ogrn">
          <el-input v-model="registerForm.ogrn" :disabled="editDisabled"/>
        </el-form-item>

        <el-form-item label="ОКПО" prop="okpo">
          <el-input v-model="registerForm.okpo" :disabled="editDisabled"/>
        </el-form-item>

        <el-form-item label="ОКАТО" prop="okato">
          <el-input v-model="registerForm.okato" :disabled="editDisabled"/>
        </el-form-item>

        <el-form-item label="ОКОГУ" prop="okogy">
          <el-input v-model="registerForm.okogy" :disabled="editDisabled"/>
        </el-form-item>

        <el-form-item label="КПП" prop="kpp">
          <el-input v-model="registerForm.kpp" :disabled="editDisabled"/>
        </el-form-item>

        <el-form-item label="ОКВЭД" prop="okwed">
          <el-input v-model="registerForm.okwed" :disabled="editDisabled"/>
        </el-form-item>

        <el-form-item label="Юридический адрес" prop="jur_address">
          <el-input v-model="registerForm.jur_address" :disabled="editDisabled"/>
        </el-form-item>

        <el-form-item label="Почтовый адрес (Фактический)" prop="postal_address">
          <el-input v-model="registerForm.postal_address" :disabled="editDisabled"/>
        </el-form-item>

        <el-form-item label="Телефон" prop="phone">
          <el-input v-model="registerForm.phone" :disabled="editDisabled"/>
        </el-form-item>

        <el-form-item label="Адрес электронной почты" prop="email_address">
          <el-input v-model="registerForm.email_address" :disabled="editDisabled"/>
        </el-form-item>

        <el-form-item label="ФИО руководителя" prop="dir_fio">
          <el-input v-model="registerForm.dir_fio" :disabled="editDisabled"/>
        </el-form-item>

        <el-form-item label="Расчетный счет" prop="bank_account">
          <el-input v-model="registerForm.bank_account" :disabled="editDisabled"/>
        </el-form-item>

        <el-form-item label="Корреспондентский счет" prop="bank_corr">
          <el-input v-model="registerForm.bank_corr" :disabled="editDisabled"/>
        </el-form-item>

        <el-form-item label="БИК банка" prop="bank_bik">
          <el-input v-model="registerForm.bank_bik" :disabled="editDisabled"/>
        </el-form-item>

        <el-form-item label="Наименование банка" prop="bank_name">
          <el-input v-model="registerForm.bank_name" :disabled="editDisabled"/>
        </el-form-item>

        <el-form-item label="Серия паспорта" prop="passport_series">
          <el-input v-model="registerForm.passport_series" :disabled="editDisabled"/>
        </el-form-item>

        <el-form-item label="Номер паспорта" prop="passport_number">
          <el-input v-model="registerForm.passport_number" :disabled="editDisabled"/>
        </el-form-item>

        <el-form-item label="Кем выдан" prop="issued_by">
          <el-input v-model="registerForm.issued_by" :disabled="editDisabled"/>
        </el-form-item>

        <el-form-item label="Когда выдан" prop="passport_issued_at">
          <DatePicker
            style="width: 300px; margin-left: 2px"
            :disabled="editDisabled"
            v-model="registerForm.passport_issued_at"
            :placeholder="'Выберите дату выдачи паспорта'"
          />
        </el-form-item>

        <el-form-item label="Прописка" prop="registration">
          <el-input v-model="registerForm.registration" :disabled="editDisabled"/>
        </el-form-item>

        <el-form-item label="Система налогообложения" prop="tax_system">
          <el-select v-model="registerForm.tax_system" :value-key=registerForm.tax_system  placeholder="Система налогооблажения" :disabled="editDisabled">
            <el-option :label="taxSystemType.name" :value="taxSystemType.id" v-for="taxSystemType in TAX_SYSTEM_TYPES" :key="taxSystemType.id"></el-option>
          </el-select>
        </el-form-item>

          <el-row :gutter="16">
            <el-col :span="16">
              <el-form-item label="Производилась ли регистрация по доверенности" prop="is_procuratory">
                <el-radio-group v-model="registerForm.is_procuratory" required>
                  <el-radio
                    :disabled="editDisabled"
                    v-for="item in is_procuratory"
                    :key="item.value"
                    :label="item.value"
                  >{{ item.label }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>

        <el-form-item label="Срок действия доверенности" prop="date_range">
          <el-date-picker
            :disabled="editDisabled"
            class="input--date-range"
            v-model="registerForm.date_range"
            type="daterange"
            start-placeholder="Дата от"
            end-placeholder="Дата до"
            :style="{height: '42px', 'margin-right': '10px'}"
          />
        </el-form-item>

        <el-form-item label="Фото документов" v-if="isViewPhotoUpload">
          <BaseSelect
            v-if="!loadingPhotoTypes"
            v-model="currentPhotoType"
            :options="photoTypes"
            placeholder="Фото документов"
            @change="photoTypeSelected"
          />
          <p v-else v-loading="loadingPhotoTypes">Загружаем типы фото</p>
          <p v-if="!currentPhotoType" style="margin-left: 10px; color: #82929d;">Для загрузки фото,
            выберите тип документов</p>
        </el-form-item>
        <el-form-item prop="partner_photo">
          <el-upload
            :disabled="editDisabled"
            v-if="isViewPhotoUpload"
            v-model:file-list="partnerPhoto"
            :drag="true"
            accept="image/jpeg,image/gif,image/png"
            list-type="picture-card"
            :on-preview="handlePictureCardPreview"
            :on-remove="deletePartnerPhoto"
            :auto-upload="true"
            :http-request="uploadPartnerFile"
            :limit="partnerPhotoLimit"
            :on-exceed="notificationMaxLimit"
            :on-change="partnerHandleChange"
          >
            <el-icon v-if="!editDisabled">
              <component :is="Plus"/>
            </el-icon>
          </el-upload>

          <el-dialog v-model="dialogVisible">
            <img class="w-100" :src="this.dialogImageUrl" alt="Preview Image"/>
          </el-dialog>
        </el-form-item>

        <el-card shadow="never" v-if="isAdmin">
          <template #header>
            <div class="card-header">
              <span>Комментарии</span>
            </div>
          </template>
          <p v-for="(item, index) in comments" :key="item" class="text item" style="padding-bottom: 10px">{{ index }}: {{ item.comment }}</p>
        </el-card>
      </template>
      <template v-slot:TabTwo>
        <el-form-item label="Адрес точки выдачи" prop="point_address">
          <el-autocomplete
            style="width: 100%;"
            v-model="registerForm.point_address"
            :disabled="editDisabled"
            :fetch-suggestions="getDaDataSuggestions"
            placeholder="Адрес точки выдачи"
            :trigger-on-focus="false"
            :debounce="1000"
            @select="handleSelect"
          />
        </el-form-item>

        <el-form-item label="Тип здания, в котором расположена точка выдачи" prop="type_building">
          <el-select v-model="registerForm.type_building" :value-key=registerForm.type_building  placeholder="Тип здания" :disabled="editDisabled">
            <el-option :label="buildingType.name" :value="buildingType.id" v-for="buildingType in BUILDING_TYPES" :key="buildingType.id"></el-option>
          </el-select>
        </el-form-item>

        <div style="padding: 20px 0" v-if="isViewPhotoUpload">
          <el-form-item label="Фото точки выдачи" prop="photo_point">
            <el-upload :disabled="editDisabled"
                       v-model:file-list="fileList"
                       :drag="true"
                       accept="image/jpeg,image/gif,image/png"
                       list-type="picture-card"
                       :on-preview="handlePictureCardPreview"
                       :on-remove="deletePhotoPoint"
                       :auto-upload="true"
                       :http-request="uploadFile"
                       :limit="photoLimit"
                       :on-exceed="notificationMaxLimit"
                       :on-change="handleChange"
            >
              <el-icon v-if="!editDisabled">
                <component :is="Plus"/>
              </el-icon>
            </el-upload>

            <el-dialog v-model="dialogVisible">
              <img class="w-100" :src="this.dialogImageUrl" alt="Preview Image"/>
            </el-dialog>
          </el-form-item>
        </div>

        <el-form-item label="Описание “Как пройти”" prop="path_description">
          <el-input v-model="registerForm.path_description" :disabled="editDisabled" />
        </el-form-item>

        <el-form-item label="График работы" prop="schedule_scheme_id">
          <el-select v-model="registerForm.schedule_scheme_id" :value-key=registerForm.schedule_scheme_id  placeholder="График работы" :disabled="editDisabled">
            <el-option :label="scheduleScheme.title" :value="scheduleScheme.id" v-for="scheduleScheme in scheduleSchemes" :key="scheduleScheme.id"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="Карта">
          <iframe
            :key="mapKey"
            v-bind:src="newMapUrl ?? mapUrl"
            width="600"
            height="400"
            frameborder="0"
            allowfullscreen
            scrolling="no"
            style="overflow: hidden;"
          ></iframe>
        </el-form-item>
      </template>
    </TabStrip>


    <div style="display:flex; margin-top: 20px;">
      <el-form-item>
        <el-button v-loading="loadingSave" :disabled="editDisabled"  type="primary" @click="saveForm(ruleFormRef)">
          Сохранить
        </el-button>
      </el-form-item>

      <el-form-item v-if="!isAdmin" style="padding-left: 10px;">
        <el-button v-loading="loadingSendCheck" :disabled="registerForm.status !== REQUEST_STATUS_NEW" type="primary" @click="sendCheckForm(ruleFormRef)">
          {{ registerForm.status !== REQUEST_STATUS_NEW ? 'Заявка отправлена' : 'Отправить на проверку' }}
        </el-button>
      </el-form-item>
    </div>

    <el-form-item>
      <template v-if="isAdmin && isDisableForm">
        <div class="alert alert-success d-flex align-items-center" role="alert">
          <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Success:"><use xlink:href="#check-circle-fill"/></svg>
          <div>
            Работа с заявкой завершена, дальнейшие изменения необходимо производить в <a :href="registerForm.partner_edit_link" target="_blank" class="alert-link">карточке партнёра</a>.
          </div>
        </div>
      </template>
    </el-form-item>

  </el-form>

  <svg xmlns="http://www.w3.org/2000/svg" style="display: none;">
    <symbol id="check-circle-fill" fill="currentColor" viewBox="0 0 16 16">
      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
    </symbol>
  </svg>
</template>

<script>
import {reactive, ref} from "vue";
import {Loading, Plus} from "@element-plus/icons-vue";
import {
  REQUEST_STATUS_CHECK,
  BUILDING_TYPES,
  REQUEST_STATUS_NEW,
  TAX_SYSTEM_TYPES
} from "@/constants";
import {ElMessageBox, ElNotification } from "element-plus";
import { UploadUserFile } from 'element-plus'
import {REQUEST_STATUS_APPROVED} from "../../constants";
import { TabStrip } from '@progress/kendo-vue-layout';
import DatePicker from "../toolbar/DatePicker.vue";
import {
  deletePartnerPhoto,
  getPhotoTypes, getPhotoTypesByRequestId,
  getRequestRegistrationCommentsByRequestId,
  uploadPartnerPhoto
} from "./api/api";
import BaseSelect from "../toolbar/BaseSelect.vue";
import Loader from "../../App.vue";

const baseURL = process.env.VUE_APP_API_ROOT;
const getPhotoUrl = baseURL + '/v2/registration-user/getPhotoPoint/';
const getPartnerPhotoUrl = baseURL + '/v2/partner-photo/show/';

export default {
  name: "RegistrationLegalEntityForm",
  props: {
    isAdmin: Boolean,
    userId: Number,
    requestId: Number
  },
  components: {
    Loading,
    Loader,
    TabStrip,
    DatePicker,
    BaseSelect
  },
  setup() {
    const ruleFormRef = ref()
    const labelPosition = ref('top')
    const fileList = ref<UploadUserFile>([])

    const registerForm = reactive({
      request_id: '',
      inn: '',
      full_name: '',
      short_name: '',
      registration_certificate: '',
      okpo: '',
      okato: '',
      okogy: '',
      kpp: '',
      okwed: '',
      jur_address: '',
      postal_address: '',
      phone: '',
      email_address: '',
      dir_fio: '',
      bank_account: '',
      bank_corr: '',
      bank_bik: '',
      bank_name: '',
      tax_system: '',
      point_address: '',
      type_building: '',
      path_description: '',
      schedule_scheme_id: '',
      status: REQUEST_STATUS_NEW,
      partner_edit_link: null,
      is_procuratory: null,
      date_range: null,
      passport_issued_at: null,
      passport_series: null,
      passport_number: null,
      issued_by: null,
      registration: null,
      partnerPhoto: []
    })

    const rules = reactive({
      inn: [
        {
          required: true,
          message: 'Введите инн',
          trigger: 'blur'
        },
      ],
      full_name: [
        {
          required: true,
          message: 'Введите полное фирменное наименование',
          trigger: 'blur'
        },
      ],
      short_name: [
        {
          required: true,
          message: 'Введите сокращенное фирменное наименование',
          trigger: 'blur'
        },
      ],
      registration_certificate: [
        {
          required: true,
          message: 'Введите свидетельство о постановке на учет юридического лица в налоговом органе',
          trigger: 'blur'
        },
      ],
      phone: [
        {
          required: true,
          message: 'Введите телефон',
          trigger: 'blur'
        },
      ],
      jur_address: [
        {
          required: true,
          message: 'Введите юридический адрес',
          trigger: 'blur'
        },
      ],
      postal_address: [
        {
          required: true,
          message: 'Введите почтовый адрес',
          trigger: 'blur'
        },
      ],
      email_address: [
        {
          required: true,
          message: 'Введите email',
          trigger: 'blur'
        },
      ],
      dir_fio: [
        {
          required: true,
          message: 'Введите ФИО руководителя',
          trigger: 'blur'
        },
      ],
      bank_account: [
        {
          required: true,
          message: 'Введите расчетный счет',
          trigger: 'blur'
        },
      ],
      bank_corr: [
        {
          required: true,
          message: 'Введите корреспондентский счет',
          trigger: 'blur'
        },
      ],
      bank_bik: [
        {
          required: true,
          message: 'Введите БИК банка',
          trigger: 'blur'
        },
      ],
      bank_name: [
        {
          required: true,
          message: 'Введите наименование банка',
          trigger: 'blur'
        },
      ],
      point_address: [
        {
          required: true,
          message: 'Введите адрес точки выдачи',
          trigger: 'blur'
        },
      ],
      tax_system: [
        {
          required: true,
          message: 'Не указан тип налогооблажения',
          trigger: 'blur'
        },
      ],
      is_procuratory: [
        {
          required: true,
          message: 'Производилась ли регистрация по доверенности?',
          trigger: 'blur'
        },
      ],
      date_range: [
        {
          required: true,
          message: 'Не указан срок действия доверенности',
          trigger: 'blur'
        },
      ],
      passport_series: [
        {
          required: true,
          message: 'Не указана серия паспорта',
          trigger: 'blur'
        },
      ],
      passport_number: [
        {
          required: true,
          message: 'Не указан номер паспорта',
          trigger: 'blur'
        },
      ],
      issued_by: [
        {
          required: true,
          message: 'Не указано, кем выдан',
          trigger: 'blur'
        },
      ],
      passport_issued_at: [
        {
          required: true,
          message: 'Не указано, когда выдан',
          trigger: 'blur'
        },
      ],
      registration: [
        {
          required: true,
          message: 'Не указана прописка',
          trigger: 'blur'
        },
      ],
    })

    return {
      ruleFormRef,
      rules,
      registerForm,
      labelPosition,
      fileList,
    }
  },
  async created() {
    await this.$store.dispatch("apiV2ScheduleSchemeStore/getScheduleSchemes")

    if (this.isAdmin === false) {
      this.getRequestRegistration();
    }
    getPhotoTypes().then((data) => {
      this.allPhotoTypes = data.data.map((item) => {
        return {value: item.type, title: item.description};
      });
    }).finally(() => {
      this.loadingPhotoTypes = false;
    });
  },
  data() {
    return {
      Plus,
      REQUEST_STATUS_CHECK: REQUEST_STATUS_CHECK,
      REQUEST_STATUS_NEW: REQUEST_STATUS_NEW,
      BUILDING_TYPES: BUILDING_TYPES,
      TAX_SYSTEM_TYPES: TAX_SYSTEM_TYPES,
      dialogImageUrl: '',
      dialogVisible: false,
      newPhotoId: null,
      isViewPhotoUpload: false,
      loadingSave: false,
      loadingSendCheck: false,
      loadingRequisites: false,
      isDisableForm: false,
      photoLimit: 3,
      partnerPhotoLimit: 4,
      message: '',
      selectedTab: 0,
      tabs: [
        {
          title: 'Заявка партнера',
          content: 'TabOne',
        },
        {
          title: 'Заявка ПВЗ',
          content: 'TabTwo',
        },
      ],
      comments: null,
      currentPhotoType: null,
      photoTypes: null,
      allPhotoTypes: null,
      loadingPhotoTypes: true,
      is_procuratory: [
        {
          value: true,
          label: 'Да'
        },
        {
          value: false,
          label: 'Нет'
        },
      ],
      address_lat: '54.970634',
      address_lon: '82.876771',
      address_zoom: 6,
      new_address_zoom: 19,
      latest_tips: [],
      mapKey: 0,
      newMapUrl: null
    }
  },
  computed: {
    editDisabled() {
      return this.registerForm.request_id !== '' && this.registerForm.status !== REQUEST_STATUS_NEW && !this.isAdmin
    },
    mapUrl() {
      return `${process.env.VUE_APP_URL_ROOT}map/${this.address_lat}/${this.address_lon}/${this.address_zoom}/true`
    },
    scheduleSchemes() {
      return this.$store.state.apiV2ScheduleSchemeStore.list;
    }
  },
  methods: {
    getPhotoTypeDescription(type) {
      if (!this.allPhotoTypes) return 'Неизвестный тип';
      const photoType = this.allPhotoTypes.find(item => item.value === type);
      return photoType ? photoType.title : 'Неизвестный тип';
    },
    getPartnerPhotoTypes() {
      if (this.registerForm.request_id) {
        getPhotoTypesByRequestId(this.registerForm.request_id).then((data) => {
          this.photoTypes = data.data.map((item) => {
            return {value: item.type, title: item.description};
          });
        }).finally(() => {
          this.loadingPhotoTypes = false;
        });
      }
    },
    handleSelect(item) {
      let address = this.latest_tips.addresses.find(address => address.value === item.value);
      this.address_lat = address.data.geo_lat
      this.address_lon = address.data.geo_lon
      this.address_zoom = 15
      this.mapKey++
    },

    onSelectTab: function (e) {
      this.selectedTab = e.selected;
    },

    handlePictureCardPreview(uploadFile) {
      this.dialogImageUrl = uploadFile.url;
      this.dialogVisible = true
    },

    saveForm() {
      this.loadingSave = true;
      if (this.registerForm.inn.length === 0) {
        ElNotification({
          title: 'Ошибка',
          message: 'Заполните поле ИНН',
          type: 'error',
        });
        this.loadingSave = false;
        return;
      }

      this.$store.dispatch('apiV2RegistrationStore/saveInfoUserLegalEntity', this.registerForm).then((result) => {
        this.registerForm.request_id = result.legalEntity.id;
        this.registerForm.status = result.legalEntity.status;
        this.fileList = [];

        this.isViewPhotoUpload = true;

        this.loadingSave = false;
        ElNotification({
          title: 'Успешно',
          message: 'Заявка сохранена',
          type: 'success',
        });
      }, error => {
        ElNotification({
          title: 'Ошибка',
          message: error.message,
          type: 'error',
        });

        this.loadingSave = false;
      });
    },

    sendCheckForm(formEl) {
      if (!formEl) return
      formEl.validate(async (valid) => {
        if (valid) {
          if (this.fileList.length === 0) {
            ElNotification({
              title: 'Ошибка',
              message: 'Загрузите фото точки выдачи',
              type: 'error',
            });

            return;
          }

          if (this.partnerPhoto.length === 0) {
            ElNotification({
              title: 'Ошибка',
              message: 'Загрузите фото документов',
              type: 'error',
            });

            return;
          }

          await ElMessageBox.confirm(
              'Отправить заявку?',
              {
                confirmButtonText: 'Да',
                cancelButtonText: 'Нет',
                cancelButtonClass: 'el-button--info'
              },
          )

          this.loadingSendCheck = true;

          this.$store.dispatch('apiV2RegistrationStore/sendRequestRegistrationToCheck', this.registerForm).then(() => {
            this.loadingSendCheck = false;
            this.registerForm.status = REQUEST_STATUS_CHECK;

            ElNotification({
              title: 'Успешно',
              message: 'Проверка документов будет осуществлена в течение 24-х часов. Информация о статусе заявки поступит на указанную Вами электронную почту',
              type: 'success',
            });
          }, error => {
            ElNotification({
              title: 'Ошибка',
              message: error.message,
              type: 'error',
            });
            this.loadingSendCheck = false;

          })
        } else {
          ElNotification({
            title: 'Ошибка',
            message: 'Не заполнены все обязательные поля',
            type: 'error',
          });

          this.loadingSendCheck = false;
        }
      })
    },

    async uploadFile(file) {
      let data = new FormData();

      data.append('attach', file.file);
      data.append('request_id', this.registerForm.request_id);

      await this.$store.dispatch('apiV2RegistrationStore/uploadPhotoPoint', data).then((result) => {
        this.newPhotoId = result.photo.id;

        ElNotification({
          title: 'Успешно',
          message: 'Фото загружено',
          type: 'success',
        });

        this.getRequestRegistration()
      });
    },

    async uploadPartnerFile(file) {
      if (!this.currentPhotoType) {
        ElNotification({
          title: 'Ошибка',
          message: 'Выберите тип фотографии',
          type: 'error',
        });
      }
      let data = new FormData();

      data.append('attach', file.file);
      data.append('request_id', this.registerForm.request_id);
      data.append('type', this.currentPhotoType);

      await uploadPartnerPhoto(data).then((result) => {
        this.newPartnerPhotoId = result.photo.id;

        ElNotification({
          title: 'Успешно',
          message: 'Фото загружено',
          type: 'success',
        });

        this.currentPhotoType = null;
        this.getRequestRegistration()
      });
    },

    deletePhotoPoint(deletePhoto) {
      let photoId = deletePhoto.id ?? deletePhoto.response;

      this.$store.dispatch('apiV2RegistrationStore/deletePhotoPoint', {photoId: photoId}).then(() => {
        ElNotification({
          title: 'Успешно',
          message: 'Фото удалено',
          type: 'success',
        });

        this.getRequestRegistration()
      });
    },

    async deletePartnerPhoto(deletePhoto) {
      let photoId = deletePhoto.id ?? deletePhoto.response;

      await deletePartnerPhoto({photoId: photoId}).then(() => {
        ElNotification({
          title: 'Успешно',
          message: 'Фото удалено',
          type: 'success',
        });

        this.getRequestRegistration();
      });
    },

    loadRequisites() {
      this.loadingRequisites = true;

      this.$store.dispatch('apiV2RegistrationStore/loadRequisitesLegalEntity', this.registerForm.inn).then((result) => {
        if (result.requisites.length === 0) {
          ElNotification({
            title: 'Не найдено',
            message: 'По инн ' + this.registerForm.inn + ' ничего не удалось найти',
            type: 'warning',
          });

          this.loadingRequisites = false;

          return;
        }

        this.registerForm.full_name = result.requisites[0].data.name.full_with_opf;
        this.registerForm.short_name = result.requisites[0].data.name.short_with_opf;

        this.registerForm.okpo = result.requisites[0].data.okpo;
        this.registerForm.ogrn = result.requisites[0].data.ogrn;
        this.registerForm.okato = result.requisites[0].data.okato;
        this.registerForm.okogy = result.requisites[0].data.okogu;
        this.registerForm.kpp = result.requisites[0].data.kpp;
        this.registerForm.okwed = result.requisites[0].data.okved;
        this.registerForm.jur_address = result.requisites[0].data.address.value;
        this.registerForm.dir_fio = result.requisites[0].data.management.name;

        this.loadingRequisites = false;

        ElNotification({
          title: 'Успешно',
          message: 'Реквизиты загружены',
          type: 'success',
        });
      });
    },

    loadForm(data) {
      if (data.registrationRequest !== null) {
        this.isViewPhotoUpload = true;

        this.registerForm.request_id = data.registrationRequest.id;
        this.registerForm.inn = data.registrationRequest.partner_registration_request.inn;
        this.registerForm.full_name = data.registrationRequest.partner_registration_request.full_name;
        this.registerForm.short_name = data.registrationRequest.partner_registration_request.short_name;
        this.registerForm.registration_certificate = data.registrationRequest.partner_registration_request.registration_certificate;
        this.registerForm.okpo = data.registrationRequest.partner_registration_request.okpo;
        this.registerForm.ogrn = data.registrationRequest.partner_registration_request.ogrn;
        this.registerForm.okato = data.registrationRequest.partner_registration_request.okato;
        this.registerForm.okogy = data.registrationRequest.partner_registration_request.okogy;
        this.registerForm.kpp = data.registrationRequest.partner_registration_request.kpp;
        this.registerForm.okwed = data.registrationRequest.partner_registration_request.okwed;
        this.registerForm.jur_address = data.registrationRequest.partner_registration_request.jur_address;
        this.registerForm.postal_address = data.registrationRequest.partner_registration_request.postal_address;
        this.registerForm.phone = data.registrationRequest.partner_registration_request.phone;
        this.registerForm.email_address = data.registrationRequest.partner_registration_request.email_address;
        this.registerForm.dir_fio = data.registrationRequest.partner_registration_request.dir_fio;
        this.registerForm.bank_account = data.registrationRequest.partner_registration_request.bank_account;
        this.registerForm.bank_corr = data.registrationRequest.partner_registration_request.bank_corr;
        this.registerForm.bank_bik = data.registrationRequest.partner_registration_request.bank_bik;
        this.registerForm.bank_name = data.registrationRequest.partner_registration_request.bank_name;
        this.registerForm.point_address = data.registrationRequest.address;
        this.registerForm.tax_system = data.registrationRequest.partner_registration_request.tax_system;
        this.registerForm.type_building = data.registrationRequest.type_building;
        this.registerForm.path_description = data.registrationRequest.path_description;
        this.registerForm.schedule_scheme_id = data.registrationRequest.schedule_scheme_id;
        this.registerForm.status = data.registrationRequest.status;
        this.registerForm.partner_edit_link = data.registrationRequest.partner_edit_link;
        this.registerForm.is_procuratory = data.registrationRequest.partner_registration_request.is_procuratory;
        this.registerForm.date_range = [data.registrationRequest.partner_registration_request.procuratory_valid_from, data.registrationRequest.partner_registration_request.procuratory_valid_to];
        this.registerForm.passport_series = data.registrationRequest.partner_registration_request.passport_series;
        this.registerForm.passport_number = data.registrationRequest.partner_registration_request.passport_number;
        this.registerForm.issued_by = data.registrationRequest.partner_registration_request.issued_by;
        this.registerForm.passport_issued_at = data.registrationRequest.partner_registration_request.passport_issued_at;
        this.registerForm.registration = data.registrationRequest.partner_registration_request.registration;

        if (data.registrationRequest.status === REQUEST_STATUS_APPROVED) {
          this.isDisableForm = true;
        }

        this.message = data.registrationRequest.comment;

        if (data.registrationRequest.point_photos !== null) {
          this.fileList = data.registrationRequest.point_photos.map((photo) => ({
            id: photo.id,
            name: photo.name,
            url: getPhotoUrl + photo.id
          }));
        }
        if (data.registrationRequest.partner_photos !== null) {
          this.partnerPhoto = data.registrationRequest.partner_photos.map((photo) => ({
            id: photo.id,
            name: photo.name,
            url: getPartnerPhotoUrl + photo.id,
            type: photo.type
          }));
        }
        if (data.registrationRequest.address !== null) {
          this.$store.dispatch('apiV2RegistrationStore/getDaDataSuggestions', data.registrationRequest.address).then((data) => {
            if (Array.isArray(data.addresses) && data.addresses.length > 0) {
              this.newMapUrl = `${process.env.VUE_APP_URL_ROOT}map/${data.addresses[0].data.geo_lat}/${data.addresses[0].data.geo_lon}/${this.new_address_zoom}/true`
            }
          })
        }
        this.getPartnerPhotoTypes()
      }
    },

    getDaDataSuggestions(address, returnResult) {
      this.$store.dispatch('apiV2RegistrationStore/getDaDataSuggestions', address).then((data) => {
        let result = [];

        data.addresses.forEach((item) => {
          result.push({value: item.value});
        });

        this.latest_tips = data
        returnResult(result);
      })
    },

    clear() {
      this.$refs.ruleFormRef.resetFields()
      this.comments = null
      this.newMapUrl = null
    },

    handleChange(file) {
      file.response = this.newPhotoId;
    },

    partnerHandleChange(file) {
      file.response = this.newPartnerPhotoId;
    },

    loadFormDialog() {
      getRequestRegistrationCommentsByRequestId(this.requestId).then((data) => {
        this.comments = data.data.comments;
      });

      return this.$store.dispatch('apiV2RegistrationStore/getRequestRegistrationById', this.requestId).then((data) => {
        this.loadForm(data);
      });
    },

    getRequestRegistration() {
      this.$store.dispatch('apiV2RegistrationStore/getInfoUserLegalEntity', this.userId).then((data) => {
        if (data !== null) {
          this.loadForm(data);
        }
      })
    },

    notificationMaxLimit() {
      ElNotification({
        title: 'Предупреждение',
        message: 'Загружено максимальное количество фотографий',
        type: 'warning',
      });
    }
  }
}
</script>

<style scoped>
  .comment {
    padding: 0 0 30px 0;
  }

  ::v-deep .el-upload-dragger {
    width: 100%;
    height: 100%;
  }

  ::v-deep .loader__backdrop {
    display: none;
  }
  .custom-file-card {
    position: relative;
    display: inline-block;
  }

  .file-description {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    text-align: center;
    padding: 5px;
    box-sizing: border-box;
  }
</style>
